<template>
  <div>
    <div class="loader" v-if="loading"></div>
    <div class="container" v-show="!loading">
      <h1 class="textCenter">Profile Detail</h1>
      <div class="col-xl-9 mx-auto px-0">
        <div class="row content-form-wrp">
          <div class="col-lg-3 propic">
            <div class="card bg-shadow border-0 h-100">
              <div class="card-body h-100 photodisplay d-flex flex-column">
                <h4 class="mb-0 textCenter">Profile Photo</h4>
                <div class="round-container mb-3">
                  <div>
                    <label for="file-upload">
                     
                      <img
                      v-if="information.profileurl"
                        class="img-account-profile rounded-circle"
                        :src="information.profileurl"
                        alt="your image"
                      />
                      <img
                      v-else
                        class="img-account-profile rounded-circle"
                        :src="user.user.profile_image !== null
                        ? `${baseAPI}${information.profile}` : `https://ui-avatars.com/api/?background=0D8ABC&color=fff&name=${user.user.name}&size=200`"
                        alt="your image"
                      />
                    </label>
                    <input
                      hidden
                      type="file"
                      id="file-upload"
                      name="image"
                      accept="image/x-png,image/jpeg,image/jpg"
                      @change="selectedFile($event)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-9">
            <div class="card bg-shadow h-100">
              <div class="card-body">
                <form novalidate class="">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="">Name</label
                        ><input
                          autocomplete="off"
                          type="text"
                          formcontrolname="Name"
                          required
                          class="form-control"
                          placeholder="Enter Name"
                          v-model="information.name"
                          @blur="$v.information.name.$touch()"
                        />
                        <template v-if="$v.information.name.$error">
                          <p
                            class="errorMessage"
                            v-if="!$v.information.name.required"
                          >
                            Name is required*
                          </p>
                        </template>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="">Email Address</label
                        ><input
                          autocomplete="off"
                          type="email"
                          formcontrolname="email"
                          required
                          email
                          class="form-control"
                          placeholder="Enter Email Address"
                          v-model.trim="information.email"
                          @blur="$v.information.email.$touch()"
                        />
                        <template v-if="$v.information.email.$error">
                          <p
                            class="errorMessage"
                            v-if="!$v.information.email.required"
                          >
                            Email is required*
                          </p>
                          <p
                            class="errorMessage"
                            v-if="!$v.information.email.email"
                          >
                            Email is invalid*
                          </p>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="accept">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="">Current Password</label>
                        <div class="visible-position">
                          <div>
                            <input
                              autocomplete="off"
                              :type="showOldPassword ? 'text' : 'password'"
                              formcontrolname="Name"
                              required
                              class="form-control"
                              placeholder="Enter old password"
                              v-model.trim="information.oldPassword"
                              @blur="$v.information.oldPassword.$touch()"
                            />
                          </div>
                          <div class="control visible-btn">
                            <button
                              class="button"
                              @click.prevent="
                                showOldPassword = !showOldPassword
                              "
                            >
                              <span class="icon is-small is-right">
                                <i
                                  class="fas"
                                  :class="
                                    showOldPassword ? 'fa-eye' : 'fa-eye-slash'
                                  "
                                ></i>
                              </span>
                            </button>
                          </div>
                        </div>
                        <template v-if="$v.information.oldPassword.$error">
                          <p
                            class="errorMessage"
                            v-if="!$v.information.oldPassword.required"
                          >
                            Old password is required*
                          </p>
                        </template>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="">New Password</label>
                        <div class="visible-position">
                          <div>
                            <input
                              autocomplete="off"
                              :type="showNewPassword ? 'text' : 'password'"
                              formcontrolname="newPassword"
                              required
                              class="form-control"
                              placeholder="Enter new password"
                              v-model.trim="information.newPassword"
                              @blur="$v.information.newPassword.$touch()"
                            />
                          </div>
                          <div class="control visible-btn">
                            <button
                              class="button"
                              @click.prevent="
                                showNewPassword = !showNewPassword
                              "
                            >
                              <span class="icon is-small is-right">
                                <i
                                  class="fas"
                                  :class="
                                    showNewPassword ? 'fa-eye' : 'fa-eye-slash'
                                  "
                                ></i>
                              </span>
                            </button>
                          </div>
                        </div>
                        <template v-if="$v.information.newPassword.$error">
                          <p
                            class="errorMessage"
                            v-if="!$v.information.newPassword.required"
                          >
                            New password is required*
                          </p>
                          <p
                            class="errorMessage"
                            v-else-if="!$v.information.newPassword.minLength"
                          >
                            New password minimum length should be 8*
                          </p>
                          <p
                            class="errorMessage"
                            v-else-if="
                              !$v.information.newPassword.containsNumber
                                .required ||
                              !$v.information.newPassword.containsUppercase
                                .required ||
                              !$v.information.newPassword.containsLowercase
                                .required ||
                              !$v.information.newPassword.containsSpecial
                                .required
                            "
                          >
                            New password must contain lowercase,uppercase,digit
                            and one special charater*
                          </p>
                        </template>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="">Confirm New Password</label>
                        <div class="visible-position">
                          <div>
                            <input
                              autocomplete="off"
                              :type="
                                showNewConfirmPassword ? 'text' : 'password'
                              "
                              formcontrolname="confirmnewPassword"
                              required
                              class="form-control"
                              placeholder="Enter confirm new password"
                              v-model.trim="information.newConfirmPassword"
                              @blur="$v.information.newConfirmPassword.$touch()"
                            />
                          </div>
                          <div class="control visible-btn">
                            <button
                              class="button"
                              @click.prevent="
                                showNewConfirmPassword = !showNewConfirmPassword
                              "
                            >
                              <span class="icon is-small is-right">
                                <i
                                  class="fas"
                                  :class="
                                    showNewConfirmPassword
                                      ? 'fa-eye'
                                      : 'fa-eye-slash'
                                  "
                                ></i>
                              </span>
                            </button>
                          </div>
                        </div>
                        <template
                          v-if="$v.information.newConfirmPassword.$error"
                        >
                          <p
                            class="errorMessage"
                            v-if="!$v.information.newConfirmPassword.required"
                          >
                            Confirm password is required*
                          </p>
                          <p
                            class="errorMessage"
                            v-else-if="
                              $v.information.newPassword !=
                              $v.information.newConfirmPassword
                            "
                          >
                            Confirm password not match with new password*
                          </p>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center flex-sm-row flex-column"
                  >
                    <button
                      @click.prevent="submitData('profile')"
                      type="submit"
                      class="btn btn-primary br-5 btnupdt"
                      
                    >
                      Update Profile Details
                      <i
                        v-if="btnloading"
                        class="fas fa-circle-notch fa-spin"
                        style="font-size: 20px; color: white"
                      ></i>
                    </button>
                    <span class="textCenter d-block forgotlink">
                      <!-- <a href="/changepassword"></a> -->
                      <!-- <router-link to="/changepassword"
                        >Change password</router-link
                      > -->
                      <div class="form-group form-check d-flex">
                        <input
                          type="checkbox"
                          v-model="accept"
                          id="accept"
                          class="form-check-input"
                        />
                        <label class="form-check-label" for="accept"
                          >Change your password ?</label
                        >
                      </div>
                    </span>
                  </div>
                </form>
                <!-- <form novalidate class="" v-if="accept"> -->

                <!-- <div class="">
                    <button
                      @click.prevent="changePassword"
                      type="submit"
                      class="btn btn-primary br-5"
                    >
                      Update Password Details
                    </button>
                  </div> -->
                <!-- </form> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required, email, sameAs, minLength } from "vuelidate/lib/validators";
import { getUserforUpdate, UserProfileUpdate } from "@/api/user";
import { mapMutations,mapGetters } from "vuex";
export default {
  data() {
    return {
      baseAPI: process.env.VUE_APP_BASE_API + process.env.VUE_APP_PROFILE_PATH,
      loading: true,
      btnloading: false,
      id: "",
      accept: false,
      showOldPassword: false,
      showNewPassword: false,
      showNewConfirmPassword: false,
      information: {
        name: "",
        email: "",
        profile: "",
        profileurl: "",
        oldPassword: "",
        newPassword: "",
        newConfirmPassword: "",
      },
      changedUseremail: "",
      isImageChanged: false,
    };
  },

  validations: {
    information: {
      name: {
        required,
      },
      email: {
          required,
        email,
      },
      oldPassword: {
        required,
      },
      newPassword: {
        required,
        minLength: minLength(8),
        containsUppercase: function (value) {
          return /[A-Z]/.test(value);
        },
        containsLowercase: function (value) {
          return /[a-z]/.test(value);
        },
        containsNumber: function (value) {
          return /[0-9]/.test(value);
        },
        containsSpecial: function (value) {
          return /[#?!@$%^&*-]/.test(value);
        },
      },
      newConfirmPassword: {
        required,
        minlength: minLength(8),
        sameAs: sameAs(function () {
          return this.information.newPassword;
        }),
      },
    },
  },
  computed:{
    ...mapGetters([ "user"]),
  },

  methods: {
    ...mapMutations("user", ["SET_USER"]),
    selectedFile(event) {
      this.isImageChanged = true;
      this.information.profile = event.target.files[0];
      this.information.profileurl = URL.createObjectURL(event.target.files[0]);
    },
    submitData(type) {
      this.$v.$touch();
     
      if(this.accept){
        if (this.information.email=='' || this.information.name =='' || this.information.oldPassword=='' || this.information.newPassword =='' || this.information.newConfirmPassword =='') return
      } else {
         if (this.information.email=='' || this.information.name =='') return
      }
      if(this.$v.invalid) return
      this.btnloading = true;
      var formData = new FormData();
      formData.append("name", this.information.name);
      formData.append("email", this.information.email);
      if (this.isImageChanged) {
        formData.append("profile_image", this.information.profile);
      }
      this.isImageChanged = false;
      // if (this.information.oldPassword=='' || this.information.newPassword =='' || this.information.newConfirmPassword =='') return
      if (this.information.oldPassword) {
        formData.append("current_password", this.information.oldPassword);
      }
      if (
        this.information.newPassword !== "" &&
        this.information.newConfirmPassword !== ""
      ) {
        formData.append("new_password", this.information.newPassword);
        formData.append(
          "confirm_password",
          this.information.newConfirmPassword
        );
      }
      if (this.changedUseremail !== this.information.email) {
        let a = confirm(
          `Are you sure you want to change your e-mail from ${this.changedUseremail} to ${this.information.email}?`
        );
        if (a) {
          this.btnloading = true;
          UserProfileUpdate(this.id, formData).then(async (res) => {
            if (this.changedUseremail !== this.information.email) {
              this.$store.dispatch("user/logout");
              this.$router.push("/");
              this.btnloading = false;
              this.$notify({
                group: "notify",
                type: "success",
                text: "Profile re-verify your email from your new mail address",
              });
            }
          });
        } else {
          console.log("cancle");
        }
      } else {
        UserProfileUpdate(this.id, formData).then(async (res) => {
          console.log(res.data.message);
          this.btnloading = false;
          if (res.data.message === "User updated successfully") {
            this.$v.$reset();
            (this.information.oldPassword = ""),
              (this.information.newPassword = ""),
              (this.information.newConfirmPassword = ""),
              (this.accept = false);
            this.$notify({
              group: "notify",
              type: "success",
              text: `${res.data.message}`,
            });
          } else {
            this.$notify({
              group: "notify",
              type: "error",
              text: `${res.data.message}`,
            });
          }
          await this.getUserProfileData();
        });
      }
      this.isImageChanged == false;
    
    },
    getUserProfileData() {
      this.loading = true;
      getUserforUpdate().then((res) => {
        this.SET_USER(res.data.data);
        this.information.name = res.data.data.name;
        this.information.email = res.data.data.email;
        this.changedUseremail = res.data.data.email;
        this.information.profile = res.data.data.profile_image;
        this.id = res.data.data.id;
        this.loading = false;
      });
    },
  },
  async created() {
    this.loading = false;
    await this.getUserProfileData();
  },
};
</script>

<style lang="scss" scoped>
.h-100 {
  height: 100%;
}
.propic {
  @media (max-width: 991px) {
    margin-bottom: 20px;
  }
}
.bg-shadow {
  box-shadow: 0 0 10px #00000029;
  border-radius: 10px;
  @media (min-width: 1921px) {
    border-radius: 0.521vw;
  }
}
.btnupdt {
  @media (max-width: 566px) {
    margin-bottom: 20px;
  }
}
.card {
  background-color: #fff;
  padding: 15px;
  @media (min-width: 1921px) {
    padding: 0.781vw;
  }
  .form-group {
    margin-bottom: 15px;
    @media (min-width: 1921px) {
      margin-bottom: 0.781vw;
    }
    input[type="text"],
    input[type="password"],
    input[type="email"] {
      height: 40px;
      border: 1px solid #d4d4d4;
      border-radius: 5px;
      box-shadow: unset;
      font-size: 14px;
      padding: 0 15px;
      width: 100%;
      @media (min-width: 1921px) {
        height: 2.083vw;
        padding: 0 0.781vw;
        font-size: 0.729vw;
      }
    }
    label {
      margin-bottom: 5px;
      display: block;
      @media (min-width: 1921px) {
        margin-bottom: 0.26vw;
        font-size: 0.833vw;
      }
    }
  }
  button {
    background-color: #2996b8;
    color: #fff;
    box-shadow: none;
  }

  .card-header {
    text-align: center;
  }
  .round-container {
    max-width: 8.958vw;
    width: 100%;
    height: 8.958vw;
    border-radius: 50%;
    margin: auto;
    overflow: hidden;
    position: relative;
    border: 1px solid #d1d5da;
    @media (max-width: 1199px) {
      max-width: 105px;
      height: 105px;
      margin-bottom: 25px;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .upload-component {
    position: relative;
    text-align: center;
    input[type="file"] {
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      left: 0;
      top: 0;
    }
  }
}
.errorMessage {
  color: red;
}
.visible-position {
  position: relative;
}
.visible-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  button {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    color: #000;
  }
}
.form-check-label {
  margin-bottom: 0 !important;
  padding-left: 5px;
}
</style>
